@use './mixin.scss' as m;
@use './variables.scss' as var;

.footer-container {
   // background-color: var.$footer-background;
   background: var.$page-background-color;
   padding-top: 3rem;

   &.indent {
      margin-top: -13rem;
      padding-top: 13rem;
   }

   .footer {
      max-width: var.$web-width;
      margin: 0 auto;
      min-height: 10rem;
      padding: 0rem;
      color: #fff;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         padding: 3rem;
      }

      .row-bottom {
         @include m.flex(row, space-between, center);
         margin-top: 5rem;
      }

      .row-top {
         @include m.flex(row, space-between, flex-start);
         flex-wrap: wrap;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            @include m.flex(row, center, flex-start);
         }

         h2 {
            margin-bottom: 2rem;
            font-family: 'MontserratBold';
         }

         li {
            @include m.flex(row, flex-start, center);
            list-style: none;
            margin: 1rem 0;

            a {
               @include m.flex(row, center, center);
               color: #fff;
               text-decoration: none;
            }
         }

         .column1 {
            width: 20%;
            @media screen and (max-width: var.$smartphone-breakpoint) {
               width: 50%;
            }

            img {
               height: 4rem;
            }

            .telephones {
               margin: 2rem 0;
            }

            a {
               text-decoration: none;
               color: dodgerblue;
            }
            .social-network-icons {
               & > * {
                  margin-right: 0.5rem;
               }
               font-size: 1.4rem;
               margin: 2rem 0;
            }
         }

         .column2 {
            @include m.flex(row, space-around, flex-start);
            width: 70%;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               width: 100%;
               order: -1;
               margin-bottom: 5rem;
            }
         }

         .column3 {
            width: 10%;
            font-size: 1.2rem;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               width: 50%;
            }

            p {
               @include m.flex(row, flex-end, center);
               margin-bottom: 1rem;

               & > svg {
                  font-size: 1.6rem;
                  margin-right: 0.5rem;
               }
            }
         }
      }
   }
}
