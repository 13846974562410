@use './mixin.scss' as m;
@use './variables.scss' as var;

.page {
   background: var.$page-background-color;

   &.landingpage-wrapper {
      background: var.$page-background-color;
   }
}

input::placeholder,
textarea::placeholder {
   color: #ccc;
   font-weight: 400;
}

select.select-transparent {
   @include m.transparentSelect();
}

.prevent-text-select {
   -webkit-user-select: none; /* Safari */
   -ms-user-select: none; /* IE 10 and IE 11 */
   user-select: none; /* Standard syntax */
}

.prevent-click {
   pointer-events: none;
}
