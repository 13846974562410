@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;

.loto-7-39-rezultati {
   color: #fff;
   padding-bottom: 1rem;
   position: relative;

   .loto739-rezultati-za-dan,
   .loto739-arhiva-rezultata {
      margin: 1rem auto;
      width: var.$web-width;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
         padding: 0;
      }
   }

   .loto739-rezultati-za-dan {
      margin-bottom: 5rem;

      h1 {
         font-family: 'MontserratBold';
         margin: 4rem auto;
      }

      .izvuceni-brojevi {
         margin: 1rem 0;
      }
   }
}
