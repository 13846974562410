@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;

.graph {
   margin: 3rem auto;

   h1 {
      font-size: 1.6rem;
      margin-bottom: 2rem;
   }

   .recharts-responsive-container {
      @media screen and (max-width: var.$smartphone-breakpoint) {
         margin-left: -3rem;
      }
   }
}
