@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-bingo.scss' as varBingo;

.bingo-rezultati {
   color: #fff;
   padding-bottom: 1rem;

   .bingo-rezultati-za-kolo,
   .bingo-arhiva-rezultata {
      margin: 1rem auto;
      width: var.$web-width;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
         padding: 0;
      }
   }

   .bingo-rezultati-za-kolo {
      margin-bottom: 5rem;

      h1 {
         font-family: 'MontserratBold';
         margin: 4rem auto;
      }

      .izvuceni-brojevi {
         margin: 1rem 0;
      }
   }
}
