@use './mixin.scss' as m;
@use './variables.scss' as var;

.loto739-iznos-dobitaka {
   table {
      width: 100%;
      font-family: 'MontserratBold';
      color: #fff;
      font-size: 1.6rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         font-size: 1.2rem;
      }

      border-collapse: separate;
      border-spacing: 0;
      // border-spacing: 0 1rem;

      tr {
         text-align: center;
         // background-color: #ddd;
         th,
         td {
            // @include m.flex(row, center, center);
            padding: 1.5rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
         }
      }
   }
}
