@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;

.izvuceni-brojevi-container {
   // all: unset;
   margin-bottom: 4rem;
   color: #fff;
   h2 {
      font-family: 'MontserratBold';
      margin: 2rem 0;

      .loto {
         color: varLoto739.$loto-color;
      }
      .lotoPlus {
         color: varLoto739.$lotoplus-color;
      }
      .jokerPlus {
         color: varLoto739.$joker-color;
      }
   }

   .ib-container {
      @include m.flex(row, space-between, center);

      @media screen and (max-width: var.$smartphone-breakpoint) {
         @include m.flex(column, space-between, flex-start);
         height: 7rem;
      }

      .numbers-container {
         @include m.flex(row, flex-start, center);
         @media screen and (max-width: var.$smartphone-breakpoint) {
            @include m.flex(row, center, center);
         }
      }

      .score-container {
         margin-right: 35rem;
         @media screen and (max-width: var.$smartphone-breakpoint) {
            margin-right: auto;
         }
      }
   }
}
