@use './variables.scss' as var;
@use './variables-smartphone.scss' as res;

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

:root {
   font-size: 62.5%;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      font-size: 50%;
   }
}

html,
body {
   min-height: 100vh;
   scroll-behavior: smooth;
   background: var.$navbar-background;
   // @media screen and (max-width: var.$smartphone-breakpoint) {
   // }
}

body {
   font-family: 'MontserratRegular';
   // background-color: #f7f7f2;
   width: 100vw;
   height: 100vh;

   #wrapper {
      background-color: var.$navbar-background;
      position: relative;
      width: 100%;
      min-height: 100vh;
      z-index: var.$z-wrapper;
      // transition: transform 0.5s ease-in-out;

      .inner-wrapper {
         position: absolute;
         top: var.$navbar-height;
         left: 0;
         transition: var.$responsive-menu-transition;
         width: 100%;
         overflow-x: hidden;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            top: res.$navbar-height;
         }

         &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 20;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: var.$responsive-menu-transition;
         }
      }
   }
}

.menu-open .inner-wrapper:before {
   opacity: 1 !important;
   visibility: visible !important;
}

.menu-open .inner-wrapper {
   transform: translateX(-(var.$navbar-responsive-width));
}

.disable-scroll {
   overflow: hidden;
}
