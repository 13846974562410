@use './mixin.scss' as m;
@use './variables.scss' as var;

.input-with-button {
   @include m.flex(row, space-between, center);
   background-color: transparent;
   border-radius: 4rem;
   border: 0.1rem solid #ccc;
   padding: 1.5rem 3rem;

   @media screen and (max-width: var.$ipad-portrait-breakpoint) {
      padding: 1.5rem 3rem;
   }

   @media screen and (max-width: var.$smartphone-breakpoint) {
      padding: 0.1rem 1rem;
      padding-right: 0;
      border-radius: 2.5rem;
   }

   input[type='text'] {
      background-color: transparent;
      border: none;
      color: #fff;
      flex-grow: 1;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 0.7rem;

      &:focus {
         outline: none;
      }
      &::placeholder {
         // font-size: 1.5rem;
         @media screen and (max-width: var.$smartphone-breakpoint) {
            font-size: 1.1rem;
         }
      }

      @media screen and (max-width: var.$smartphone-breakpoint) {
         font-size: 1.3rem;
      }
   }
}
