@use './mixin.scss' as m;
@use './variables.scss' as var;

.landing-page {
   max-width: var.$web-width;
   margin: 0 auto;
   position: relative;
   z-index: 100;

   .row > div {
      @include m.flex(column, center, center);
      text-align: center;
      // border: 4px solid #000;
   }
}
