@use './mixin.scss' as m;
@use './variables.scss' as var;

.game-panel-button {
   @include m.flex(row, center, center);
   @include m.gamePanelComponentShadow();

   background-color: var.$general-pink;
   border-radius: 2rem;
   border: 0.1rem solid var.$general-pink;
   color: #fff;
   cursor: pointer;
   font-family: 'MontserratRegular';
   font-size: 1.5rem;
   margin-left: 1rem;
   min-width: 14rem;
   padding: 1rem 0rem 1rem 1rem;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      margin-left: 0rem;
      transform: scale(0.8);
      min-width: fit-content;
      padding-right: 1rem;
   }

   &.transparent {
      background-color: transparent;
      font-size: 1.2rem;
   }

   img {
      padding-left: 1rem;
   }

   &:disabled {
      opacity: 0.5;
      cursor: default;
   }

   .icon-style {
      font-size: 1.6rem;
      margin-left: 0.5rem;
      color: var.$general-pink;
   }
}
