@use './mixin.scss' as m;
@use './variables.scss' as var;

.loading-information {
   @include m.flex(row, center, center);
   font-size: 3rem;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 20rem;
   // background-color: #000;
   color: #fff;
   z-index: 200;

   svg {
      animation: spinnerAnim 0.5s linear infinite;
   }
}

@keyframes spinnerAnim {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
