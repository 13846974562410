@use './variables.scss' as var;

.btn-menu {
    background: transparent;
    border: none;
    color: white;
    display: none;
    font-size: 4rem;
    margin-right: 2rem;
    cursor: pointer;

    @media screen and (max-width: var.$smartphone-breakpoint) {
        display: inline-block;
    }
}
