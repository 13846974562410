@use './variables.scss' as var;
@use './mixin.scss' as m;

.landscape-information {
   @include m.flex(column, center, center);

   background: black;
   color: white;
   font-size: 3rem;
   height: 100vh;
   left: 0;
   position: fixed;
   top: 0;
   width: 100vw;
   z-index: var.$z-landscape-information;
   overflow: hidden;

   svg {
      color: orange;
      font-size: 4rem;
      margin-top: 1rem;
   }
}
