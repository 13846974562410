@use './mixin.scss' as m;
@use './variables.scss' as var;

.game-o-igri-info {
   padding-top: 5rem;

   .game-o-igri-info-content {
      width: var.$web-width;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
         padding: 0;
      }

      margin: 0 auto;
      padding: 5rem 0;
   }
}
