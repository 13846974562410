@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-bingo.scss' as varBingo;

.izvuceni-bingo-brojevi-container {
   // all: unset;
   margin-bottom: 4rem;
   color: #fff;

   h2 {
      font-family: 'MontserratBold';
      margin: 2rem 0;

      .bingo {
         color: varBingo.$bingo-color;
      }
      .bingoPlus {
         color: varBingo.$bingoPlus-color;
      }
   }

   .ib-container {
      @include m.flex(row, space-between, center);

      @media screen and (max-width: var.$smartphone-breakpoint) {
         @include m.flex(column, space-between, flex-start);
      }

      .numbers-container {
         @include m.flex(row, flex-start, center);
         flex-wrap: wrap;
         width: 58rem;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            width: 44rem;
         }

         .game-number {
            margin: 0.2rem;
         }

         @media screen and (max-width: var.$smartphone-breakpoint) {
            @include m.flex(row, flex-start, center);
         }
      }
   }
}
