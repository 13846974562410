@use './mixin.scss' as m;
@use './variables.scss' as var;

.newsletter {
   @include m.flex(row, space-between, center);
   height: inherit;
   padding: 2rem;
   background: var.$newsletter-background-color;
   border-radius: var.$landingpage-panel-border-radius;
   color: #fff;

   @media screen and (max-width: var.$ipad-portrait-breakpoint) {
      flex-direction: column;
   }

   .message {
      text-align: left;
      margin-right: 2rem;

      @media screen and (max-width: var.$ipad-portrait-breakpoint) {
         text-align: center;
      }

      h2 {
         font-size: 1.8rem;
         font-weight: normal;
         margin-bottom: 1rem;
      }
      p {
         font-size: 1.2rem;
      }
   }

   .input-email {
      width: 75%;

      @media screen and (max-width: var.$ipad-portrait-breakpoint) {
         width: 100%;
      }
   }
}
