*::-webkit-scrollbar {
    width: 0.2rem;
    height: 100%;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2rem;
    border: transparent;
}
