@use './mixin.scss' as m;
@use './variables.scss' as var;

$break-line-height: 3rem;
$right-margin: 2rem;
$game-panel-padding: 3rem;

.game-panel {
   background-repeat: no-repeat;
   background-size: cover;
   height: 100%;
   padding: $game-panel-padding;
   position: relative;
   width: inherit;
   font-family: 'MontserratBold';
   font-weight: 900;
   color: #fff;

   .gp-header,
   .buttons-container {
      left: 0;
      padding: $game-panel-padding;
      position: absolute;
      width: 100%;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         padding: 2rem;
      }
   }

   .gp-header {
      @include m.flex(row, space-between, flex-start);
      top: 0;

      .gp-logo {
         width: 50%;
         // background-color: #f90;
         img {
            width: 100%;
            // max-height: 14rem;
         }
      }

      // .gp-play-btn {
      //    // background-color: #f90;
      // }
   }

   .gp-info-header {
      margin: 13rem 0 2rem;
      text-align: left;
      font-size: 2rem;
   }

   .gp-info-container {
      @include m.flex(row, flex-start, center);
      margin-bottom: 2rem;

      // & > * {
      //    border: 1px solid red;
      // }
      .gp-icon {
         margin-right: 2rem;
      }

      .gp-info {
         text-align: left;

         .gp-title {
            font-size: 2rem;
         }

         .gp-amount {
            font-size: 3rem;
         }
      }
   }

   .title-container,
   .info-container {
      bottom: 0;
      position: absolute;
      top: 0;
      width: 50%;
   }

   .buttons-container {
      @include m.flex(row, space-between, center);
      bottom: 0;

      width: 100%;

      & > .stopwatch-container {
         width: 40%;
      }

      & > .btns {
         @include m.flex(row, flex-end, center);
         width: 50%;
      }
   }

   .fullrow-container {
      height: 100%;
      @include m.flex(row, space-between, center);

      & > div {
         @include m.flex(row, center, center);
         flex: 1;
         align-self: stretch;
      }

      .left {
         font-size: 4rem;
      }
   }
}
