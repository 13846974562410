@use './mixin.scss' as m;
@use './variables.scss' as var;

.gameFAQ {
   h1 {
      color: var.$general-pink;
   }
   &.loto {
      h1 {
         color: var.$general-pink;
      }
   }

   &.bingo {
      h1 {
         color: var.$bingo-color;
      }
   }

   &.bingo-plus {
      h1 {
         color: var.$bingo-plus-color;
      }
   }

   .content {
      width: var.$web-width;
      margin: 0 auto;
      padding: 5rem 0;

      h1 {
         font-size: 2.5rem;

         &:first-of-type {
            margin-bottom: 5rem;
         }
      }

      h2 {
         font-family: 'MontserratBold';
         font-size: 2rem;
         margin: 1rem 0;
         color: #fff;

         & + p {
            font-size: 1.6rem;
            line-height: 3rem;
         }
      }

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }

      & > h1 {
         margin-bottom: 2rem;
         font-family: 'MontserratBold';
      }
   }
}
