@use './mixin.scss' as m;
@use './variables.scss' as var;

$img-size: 36rem;

.game-info-item {
   color: #fff;
   padding-bottom: 7rem;

   .header-container {
      @include m.flex(row, flex-start, flex-start);
      margin-bottom: 1.5rem;

      .header {
         font-family: 'MontserratBold';
         font-size: 2rem;
         line-height: 3rem;
      }
   }

   .header,
   .text {
      width: calc(var.$web-width - $img-size);

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }

      padding: 0 1rem;
   }

   .body-container {
      @include m.flex(row, flex-start, flex-start);

      @media screen and (max-width: var.$smartphone-breakpoint) {
         flex-direction: column;
      }

      .text {
         line-height: 3.5rem;
         font-size: 1.8rem;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            font-size: 1.4rem;
         }
      }

      .img {
         width: $img-size;
         height: $img-size;
         // border: 0.1rem solid #fff;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            margin: 2rem auto;
         }

         img {
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
}
