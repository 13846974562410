/* rezultati */
$loto-color: #f00;
$lotoplus-color: #00f;
$joker-color: #0f0;

$loto-gradient: radial-gradient(
   circle at 65% -25%,
   white 0.1rem,
   #a51111 60%,
   #c76c6c 100%
);
$lotoplus-gradient: radial-gradient(
   circle at 65% -25%,
   white 0.1rem,
   #003355 60%,
   #576975 100%
);
$joker-gradient: radial-gradient(
   circle at 65% -25%,
   white 0.1rem,
   #015210 60%,
   #3f924e 100%
);
