@mixin flex($direction, $mainAxisAlignment, $secondaryAxisAlignment) {
   display: flex;
   flex-direction: $direction;
   justify-content: $mainAxisAlignment;
   align-items: $secondaryAxisAlignment;
}

@mixin landingPagePanelShadow() {
   box-shadow: 0px 0px 1px -9px rgba(0, 0, 0, 0.07),
      0px 0px 3.1px -9px rgba(0, 0, 0, 0.1),
      0px 0px 8.3px -9px rgba(0, 0, 0, 0.131),
      0px 0px 20px -9px rgba(0, 0, 0, 0.2);
}

@mixin gamePanelComponentShadow() {
   box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.25);
}

@mixin submenuBoxShadow() {
   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.75);
}

@mixin loto739background() {
   background-image: url('../../public/img/loto/sveoigri/loto-background.png');
   background-position: left bottom;
   background-repeat: no-repeat;
   background-size: cover;
}

@mixin bingoBackground() {
   background-image: url('../../public/img/bingo/sveoigri/bingo_background.png');
   background-position: left bottom;
   background-repeat: no-repeat;
   background-size: cover;
}

@mixin transparentSelect() {
   background-color: transparent;
   border-radius: 0.5rem;
   border: 1px solid #fff;
   padding: 0.75rem 1rem;
   color: #fff;
   min-width: 12rem;

   &:focus-visible {
      outline: none;
   }

   &:last-of-type {
      margin-left: 1rem;
   }

   option {
      color: #333;
   }
}

@mixin line-clamp($lineNumber) {
   display: -webkit-box;
   -webkit-line-clamp: $lineNumber;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

@mixin h1() {
   @include flex(row, center, center);
   border-bottom: 0.1rem solid #ccc;
   box-shadow: 0 0.3rem 1rem 0 rgba(0, 0, 0, 0.2);
   font-family: 'MontserratBold';
   font-size: 3rem;
   min-height: 5rem;
   margin-bottom: 3rem;
   text-align: center;
}

@mixin dateMark() {
   @include flex(row, center, center);
   background-color: #313e4b;
   border-top-left-radius: 1rem;
   border-bottom-right-radius: 1rem;
   color: #fff;
   font-size: 1rem;
   height: 3rem;
   left: 0;
   position: absolute;
   top: 0;
   width: 13rem;
}
