/* rezultati */
$bingo-color: #f90;
$bingoPlus-color: #09f;

$bingo-gradient: radial-gradient(
   circle at 65% -25%,
   white 0.1rem,
   #445ee9 60%,
   #596feb 100%
);
$bingoPlus-gradient: radial-gradient(
   circle at 65% -25%,
   white 0.1rem,
   #2896e7 60%,
   #4dc4f4 100%
);
