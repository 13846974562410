.form-input {
   width: 100%;

   & > label,
   & > input,
   & > textarea,
   & > span.error {
      margin: 0.5rem;
   }

   &.has-label {
      margin-top: 1rem;
   }

   label {
      font-size: 1.4rem !important;
      font-weight: 700;
   }

   span.error {
      font-size: 1.4rem;
      font-weight: 700;
      color: #f00;
   }

   & > input,
   & > textarea {
      border-radius: 0.4rem;
      border: 0.1rem solid #ddd;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      font-family: 'MontserratRegular';
      padding: 1rem;
      width: 100%;

      &:focus {
         outline: 0;
      }
   }
}
