@use './mixin.scss' as m;
@use './variables.scss' as var;

.download-pdf {
   font-family: 'MontserratBold';
   color: #fff;
   & > header {
      font-size: 1.6rem;
   }

   .download-pdf-button {
      button {
         all: unset;
         @include m.flex(row, flex-start, center);
         cursor: pointer;
         font-size: 1.4rem;
         margin-top: 1rem;

         span {
            @include m.flex(row, center, center);
            font-size: 1.8rem;

            &:first-of-type {
               margin-right: 0.5rem;
            }

            &:last-of-type {
               margin-left: 0.5rem;
               color: #f00;
            }
         }
      }
   }
}
