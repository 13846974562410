@use './mixin.scss' as m;
@use './variables.scss' as var;

.text-expander {
   @include m.flex(row, flex-start, center);
   color: #fff;
   padding: 2rem 0;
   border-top: 0.1rem solid #ccc;
   // border-bottom: 0.1rem solid #ccc;

   .text-expander-container {
      width: 95%;
      transition: all 1s ease-in-out;

      .text-expander-label {
         font-size: 1.8rem;
         font-weight: 400;
      }

      .text-expander-body {
         font-size: 1.8rem;
         line-height: 3rem;
      }
   }
   .text-expander-icon {
      @include m.flex(row, center, center);
      width: 5%;
      font-size: 2rem;
   }

   .text-expander-container,
   .text-expander-icon {
      cursor: pointer;
   }
}
