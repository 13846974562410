@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;
@use './variables-bingo.scss' as varBingo;

.game-number {
   @include m.flex(row, center, center);
   font-family: 'MontserratBold';
   width: 5rem;
   height: 5rem;
   border-radius: 100%;
   color: #fff;
   font-size: 2.5rem;
   margin: 0 0.3rem;
   background-color: dodgerblue;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      font-size: 2rem;
      width: 4rem;
      height: 4rem;
   }

   &.loto {
      border: 1px solid #c76c6c;
      background: varLoto739.$loto-gradient;
   }
   &.lotoPlus {
      border: 1px solid #576975;
      background: varLoto739.$lotoplus-gradient;
   }
   &.jokerPlus {
      border: 1px solid #3f924e;
      background: varLoto739.$joker-gradient;
   }

   &.bingo {
      border: 1px solid #445ee9;
      background: varBingo.$bingo-gradient;
   }
   &.bingoPlus {
      border: 1px solid #2896e7;
      background: varBingo.$bingoPlus-gradient;
   }
}
