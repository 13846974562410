@use './mixin.scss' as m;

.izvuceni-brojevi-score {
   @include m.flex(row, flex-start, center);
   color: #fff;
   font-family: 'MontserratRegular';
   font-size: 1.4rem;

   span {
      @include m.flex(row, center, center);

      &:first-of-type {
         color: red;
         margin-right: 0.5rem;
      }
   }
}
