@use './variables.scss' as var;
@use './variables-smartphone.scss' as res;
@use './mixin.scss' as m;

.navbar-responsive {
    @include m.flex(column, space-around, center);
    background: var.$navbar-background;
    bottom: 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    right: 0;
    overflow-y: auto;
    position: fixed;
    top: var.$navbar-height;
    transform: translateX((var.$navbar-responsive-width));
    transition: var.$responsive-menu-transition;
    width: var.$navbar-responsive-width;
    z-index: var.$z-navbar-responsive;

    @media screen and (max-width: var.$smartphone-breakpoint) {
        top: res.$navbar-height;
    }

    a {
        @include m.flex(row, center, center);
        background: var.$navbar-background;
        border-bottom: 0.1rem solid darken(var.$navbar-background, 10);
        color: #fff;
        cursor: pointer;
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        text-decoration: none;
        position: relative;

        &:first-of-type {
            border-top: 0.1rem solid darken(var.$navbar-background, 10);
        }
    }
}

.menu-open .navbar-responsive {
    transform: translateX(0);
}
