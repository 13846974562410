/* varijable vezane uz dimenzije website-a */
$web-width: 117rem;
$navbar-height: 13rem;
$navbar-onscroll-height: 7rem;
$carousel-height: 50rem;

/* z indices */
$z-wrapper: 1;
$z-carousel: 10;
$z-navbar-responsive: 20;
$z-navbar: 30;
$z-landscape-information: 100;

/* responsivnes */
$smartphone-breakpoint: 900px;
$ipad-portrait-breakpoint: 992px;
$navbar-responsive-width: 32rem;
$responsive-menu-transition: all 0.5s ease-in-out;

/* COLORS */
$navbar-background: #540e6d;
$navbar-top-link-color: #fff;
$navbar-bottom-link-color: #fff;
$footer-background: #542882;
// $page-background-color: #f4f4f4;
$page-background-color: linear-gradient(to right, #592883, #1c2475);
$newsletter-background-color: linear-gradient(to right, #7a2781, #000032 40%);
$general-pink: #b53e7f;
$bingo-color: #005aff;
$bingo-plus-color: #00a0ff;

$landingpage-panel-border-radius: 1rem;
