@use './mixin.scss' as m;
@use './variables.scss' as var;

.novosti-item {
   @include m.flex(row, space-between, flex-start);
   margin-bottom: 3rem;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      @include m.flex(column, center, space-between);
   }

   .novosti-img {
      .social-network-icons-container {
         @include m.flex(row, flex-end, center);

         .social-network-icons {
            @include m.flex(row, space-between, center);
            font-size: 2.5rem;
            width: 10rem;
         }
      }
   }

   .novosti-text {
      width: 57rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }

      .novosti-header {
         @include m.flex(row, flex-start, center);
         margin: 2rem 0;
         .line {
            width: 20%;
            border-bottom: 0.1rem solid #ccc;
            position: relative;
            margin-left: -5rem;
            margin-right: 1rem;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               display: none;
            }
         }
         h2 {
            width: 85%;
            font-family: 'MontserratBold';
            font-size: 2.2rem;
            line-height: 3rem;
            color: #666;
         }
      }
      .date {
         @include m.dateMark();
      }

      .text-container {
         @include m.flex(row, center, center);

         height: 50rem;
         padding: 2rem;
         font-size: 1.4rem;
         line-height: 2.5rem;

         // p {
         //    line-clamp: 1;
         //    overflow: hidden;
         //    -webkit-box-orient: vertical;
         // }
      }
   }
}
