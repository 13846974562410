@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;

.loto-7-39-statistika {
   color: #fff;
   margin: 3rem auto;
   padding-bottom: 1rem;
   width: var.$web-width;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      width: 90vw;
      padding: 0;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
   }

   h1,
   h2 {
      margin-bottom: 2rem;
   }

   p {
      font-size: 1.6rem;
      line-height: 1.75;
      margin-bottom: 3rem;
   }
}
