@use './mixin.scss' as m;
@use './variables.scss' as var;

.news-panel {
   background: var.$newsletter-background-color;
   height: inherit;
   color: #fff;
   border-radius: var.$landingpage-panel-border-radius;

   & > .date {
      @include m.dateMark();
   }

   & > .text-container {
      @include m.flex(column, space-between, flex-start);
      height: 17.5rem;
      left: 26rem;
      position: absolute;
      text-align: left;
      top: 3rem;
      width: 29rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         height: 19.5rem;
         left: 43%;
         top: 3rem;
         width: 57%;
      }

      & > .title {
         font-size: 1.8rem;
         font-weight: 700;
         width: 100%;
      }

      & > .text {
         font-size: 1.4rem;
         line-height: 2.25rem;
         @include m.line-clamp(3);
      }

      & > .news-link {
         background-color: transparent;
         border: none;
         color: var.$general-pink;
         cursor: pointer;
         font-size: 1.1rem;
         font-weight: 700;
      }
   }

   & > .img-container {
      height: inherit;
      width: 24rem;
      background-repeat: no-repeat;
      background-size: cover !important;
      border-top-left-radius: 1.3rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 17rem;
      }
   }

   // & > img {
   //    border-radius: 0.5rem;
   //    height: 17.5rem;
   //    left: 1rem;
   //    object-fit: cover;
   //    position: absolute;
   //    top: 5rem;
   //    width: 23.5rem;

   //    @media screen and (max-width: var.$smartphone-breakpoint) {
   //       height: 11.2rem;
   //       width: 15rem;
   //    }
   // }

   // @include landingPagePanelShadow();
}
