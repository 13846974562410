@use './mixin.scss' as m;
@use './variables.scss' as var;

.contact-page {
   & > h1 {
      @include m.h1();
      height: 25rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         height: 10rem;
      }
   }

   .contact-us {
      margin: 0 auto;
      max-width: var.$web-width;
      padding-bottom: 3rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }

      & > p {
         font-size: 2rem;
         line-height: 2.6rem;
         margin: 3rem auto 8rem;
      }

      & > .banners {
         width: 80%;
         margin: auto;
         @include m.flex(row, space-around, center);

         @media screen and (max-width: var.$smartphone-breakpoint) {
            @include m.flex(column, space-between, center);
            height: 75rem;
         }

         //  .contact-banner {
         //     @media screen and (max-width: var.$smartphone-breakpoint) {
         //        margin-bottom: 7rem;
         //     }
         //  }
      }

      & > .contact-form {
         @include m.flex(row, space-between, center);
         margin-top: 4rem;

         @media screen and (max-width: var.$smartphone-breakpoint) {
            @include m.flex(column, flex-start, center);
         }

         & > .map {
            min-height: 40rem;
            width: 50%;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               width: 100%;
            }
         }

         & > .form {
            @include m.flex(column, flex-start, flex-start);
            min-height: 40rem;
            width: 50%;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               margin-top: 3rem;
               width: 100%;
            }

            form {
               width: 100%;
            }

            & > h2 {
               color: #666;
               font-family: 'MontserratBold';
               font-size: 2rem;
               padding: 1rem;
            }

            .form-input-elements {
               margin-top: 1rem;
               width: 100%;
            }
         }
      }
   }
}
