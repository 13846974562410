@use './mixin.scss' as m;
@use './variables.scss' as var;

.novosti-page {
   & > h1 {
      @include m.h1();
      height: 7rem;
   }

   .novosti-container {
      margin: 0 auto;
      max-width: var.$web-width;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }
   }
}
