@use './mixin.scss' as m;
@use './variables.scss' as var;

.game-navbar {
   @include m.submenuBoxShadow();
   height: 4rem;
   // border-top: 0.2rem solid rgba(0, 0, 0, 0.6);
   position: relative;

   .container {
      @include m.flex(row, flex-start, center);
      max-width: var.$web-width;
      margin: 0 auto;
      height: inherit;
      position: relative;

      .logo-placeholder {
         height: 60%;
         img {
            height: 100%;
         }

         @media screen and (max-width: var.$smartphone-breakpoint) {
            display: none;
         }
      }

      .links {
         position: absolute;
         top: 0;
         left: 0;
         bottom: 0;
         width: 100%;
         @include m.flex(row, center, center);

         & > a {
            color: #fff;
            margin: 0 2rem;
            font-size: 1.6rem;
            font-weight: 700;
            text-decoration: none;

            &.activeLink {
               // text-decoration: underline;
               color: var.$general-pink;
            }
         }
      }
   }
}
