@use './mixin.scss' as m;
@use './variables.scss' as var;

.stopwatch {
   @include m.flex(row, flex-start, center);
   @include m.gamePanelComponentShadow();
   border-radius: 2rem;
   border: 0.1rem solid var.$general-pink;
   color: #fff;
   font-family: 'MontserratRegular';
   font-size: 1.2rem;
   height: 4rem;
   padding: 0 1rem;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      // width: 100%;
      transform: scale(0.8);
      font-size: 1rem;
   }

   img {
      padding-right: 1rem;
   }
   .stopwatch-icon {
      font-size: 3rem;
      margin-right: 1rem;
   }
}
