@use './mixin.scss' as m;

.contact-banner {
   @include m.flex(row, center, center);
   width: 26rem;
   height: 20rem;
   position: relative;

   &,
   .contact-banner-icon {
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 0.5rem;
      border: 0.1rem solid #ccc;
   }

   .contact-banner-icon {
      @include m.flex(row, center, center);
      width: 8rem;
      height: 8rem;
      font-size: 3rem;
      color: #999;
      font-weight: 100;
      position: absolute;
      left: 50%;
      top: -4rem;
      transform: translateX(-50%);
   }

   & > .content {
      @include m.flex(column, space-between, center);
      margin-top: 4rem;
      height: 30%;

      & > h2 {
         font-family: 'MontserratBold';
         font-size: 1.6rem;
      }

      & > div {
         color: #666;
         font-size: 1.4rem;
      }
   }
}
