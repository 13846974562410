@font-face {
   font-family: 'MontserratRegular';
   src: local('MontserratRegular'),
      url('../fonts/Montserrat-Regular.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'MontserratBold';
   src: local('MontserratBold'),
      url('../fonts/Montserrat-Bold.ttf') format('truetype');
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'MontserratBlackItalic';
   src: local('MontserratBlackItalic'),
      url('../fonts/Montserrat-BlackItalic.ttf') format('truetype');
   font-weight: bold;
   font-style: normal;
}

@font-face {
   font-family: 'MontserratLight';
   src: local('MontserratLight'),
      url('../fonts/Montserrat-Light.ttf') format('truetype');
   font-weight: 100;
   font-style: normal;
}
