@use './variables.scss' as var;

.odabir-arhive {
   color: #fff;
   margin-bottom: 3rem;

   h1 {
      margin: 3rem 0;
      font-size: 1.6rem;
   }

   span {
      font-size: 1.6rem;
      font-family: 'MontserratBold';
      margin-right: 13rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         margin-right: 3rem;
      }
   }
}
