.games-container {
   padding-top: 3rem;

   & > h1 {
      color: #fff;
      font-family: 'MontserratRegular';
      font-size: 2rem;
      font-weight: 400;
      padding: 3rem 0;
   }
}
