@use './variables.scss' as var;
@use './variables-smartphone.scss' as res;

.carousel-root {
   position: relative;
   z-index: var.$z-carousel;

   img {
      // height: var.$carousel-height;
      object-fit: cover;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         height: res.$carousel-height;
      }
   }
}
