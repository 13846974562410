@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-smartphone.scss' as res;

$logo-width: 30rem;
$navbar-top-height: 5rem;
$smartphone-breakpoint: 700px;

.navbar-rs-container {
   background-color: var.$navbar-background;
   box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.3);
   height: var.$navbar-height;
   left: 0;
   position: fixed;
   top: 0;
   transition: all 0.15s ease-in-out;
   width: 100%;
   z-index: var.$z-navbar;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      height: res.$navbar-height;
   }

   .navbar-rs {
      @include m.flex(row, space-between, center);
      height: inherit;
      margin: 0 auto;
      max-width: var.$web-width;

      .navbar-rs-logo {
         width: $logo-width;
         height: inherit;

         & > a {
            @include m.flex(row, flex-start, center);
            height: inherit;
            padding-left: 1rem;

            & > img {
               height: 50%;
            }
         }
      }

      .navbar-rs-links {
         @include m.flex(column, flex-start, flex-end);

         .activeLink {
            text-shadow: 0 0 0.2rem var.$general-pink;
            color: var.$general-pink !important;
         }

         .navbar-top,
         .navbar-bottom {
            padding-right: 1rem;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               display: none !important;
            }

            & > a {
               color: #fff;
               display: inline-block;
               font-family: 'MontserratBold';
               margin-left: 2rem;
               text-decoration: none;
               transition: all 0.25s ease-in-out;

               &:hover {
                  color: var.$general-pink;
               }
            }
         }

         .navbar-top {
            @include m.flex(row, flex-end, center);
            height: $navbar-top-height;

            @media screen and (max-width: var.$smartphone-breakpoint) {
               display: none !important;
            }

            & > a {
               color: var.$navbar-top-link-color;
               font-size: 1.2rem;
            }
         }

         .navbar-bottom {
            @include m.flex(row, flex-end, center);
            height: calc(var.$navbar-height - $navbar-top-height);

            & > a {
               color: var.$navbar-bottom-link-color;
               font-size: 1.6rem;
            }
         }
      }
   }
}

.onscroll {
   .navbar-rs-container {
      height: var.$navbar-onscroll-height;
      box-shadow: 0 0rem 0.25rem var.$navbar-background;
   }
}
