@use './mixin.scss' as m;
@use './variables.scss' as var;
@use './variables-loto739.scss' as varLoto739;

.statistika-izvucenih-brojeva {
   color: white;
   margin: 2rem 0 5rem;

   h1 {
      margin-bottom: 1rem;
   }

   .container-sib {
      @include m.flex(row, space-between, center);
      width: 50%;

      & > div {
         @include m.flex(column, center, center);

         & > div:last-of-type {
            margin-top: 1rem;
            font-size: 1.6rem;
         }
      }
   }
}
