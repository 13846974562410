@use './mixin.scss' as m;
@use './variables.scss' as var;

.panel {
   @include m.landingPagePanelShadow();
   border-radius: var.$landingpage-panel-border-radius;
   margin-bottom: 2rem;
   position: relative;
   width: 57rem;
   overflow: hidden;

   @media screen and (max-width: var.$smartphone-breakpoint) {
      width: 90%;
   }

   &.game-small-cell {
      height: 28.5rem;
   }

   &.game-large-cell {
      height: 59rem;
      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }
   }

   &.game-small-row {
      height: 28.5rem;
   }

   &.game-small-row,
   &.newsletter-small-row {
      @media screen and (min-width: var.$ipad-portrait-breakpoint) {
         width: 100%;
      }
      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }
   }

   &.news-small-cell {
      height: 25rem;
   }

   &.newsletter-small-row {
      height: 21rem;
      // box-shadow: 0 0.5rem 3rem rgba(255, 255, 255, 0.5);
   }

   &.novosti-panel {
      width: 57rem;
      height: 50rem;

      @media screen and (max-width: var.$smartphone-breakpoint) {
         width: 90vw;
      }
   }
}
