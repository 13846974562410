@use './mixin.scss' as m;
@use './variables.scss' as var;

.game-o-igri {
   width: 100%;

   .header {
      color: var.$general-pink;
      font-size: 2.5rem !important;
      margin-bottom: 5rem;
   }

   // .header-container {
   //    width: 50%;
   // }
}
